import styles from './Product.module.scss';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText } from 'utils/render';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const ProductIntransitStock = ({ inventory, onRendered, leadTime }) => {
  let sanaTextsToLoad = [];
  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;
  if (typeof inventory !== 'undefined') {
    const stockText = 'PLP_IntransitStockText';
    const leadText = 'PLP_IntransitLeadTimeText';
    sanaTextsToLoad = [stockText, leadText];

  } else {
    return stockValuePlaceholder;
  }

  const culture = useCultureName();

  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, leadText]) => {
        const stockDisplyaMessage = stockText.replace('[STOCKAMOUNT]', inventory);
        let leadTimeDisplyaMessage = null;
        if (leadTime) {
          leadTimeDisplyaMessage = leadText.replace('[LEADTIME]', toLocaleDate(new Date(leadTime), culture));
        }
        onRendered && onRendered();
        return (stockText && (
          <span className="intransit-stock-wrapper">
            {/* <span className="intransit-stock-separater"> | </span> */}
            <span className="intransit-stock"> <span className="intransit">{makeRichText(stockDisplyaMessage)}</span>  <span className="available-date">{makeRichText(leadTimeDisplyaMessage)}</span> </span>
          </span>
        ));
      }}
    </UseSanaTexts>
  );
};

ProductIntransitStock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  additionalClass: PropTypes.string,
  stockTextKey: PropTypes.string,
  leadTime: PropTypes.string,
  customerType: PropTypes.string,
  onRendered: PropTypes.func,
  forVariant: PropTypes.bool,
};

export default connect(({ user: { customerType } }) => ({ customerType }))(ProductIntransitStock);
